import React, { useEffect, useState } from "react";
import Banner from "../common/banner/banner";
import Loader from "../common/loader/loader";
import bg3 from "../../images/bg/03.jpg";
import { serverLink, shortCode } from "../../resources/url";
import axios from "axios";
import { setHRStaffDataDetails, setHRStaffListDetails } from "../../actions/setactiondetails";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Avater from './avater.jpg'

const StaffList = (props) => {
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState("");
    const [titles, setTitles] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [staff_list, setStaff_List] = useState(props.hrStaffList);

    const updateStaffHitCount = async (id) => {
        await axios
            .put(`${serverLink}academic/update/staff/hit/count/${id}`)
            .then((response) => { })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    const getHRStaffList = async () => {
        await axios
            .get(`${serverLink}academic/staff/all/`)
            .then((response) => {
                props.setOnHRStaffListDetails(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academic/titles/`)
            .then((response) => {
                setTitles(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academic/designation/`)
            .then((response) => {
                setDesignation(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    const getHRStaffData = async () => {
        await axios
            .get(`${serverLink}academic/staff/data/`)
            .then((response) => {
                props.setOnHRStaffDataDetails(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    useEffect(() => {
        getHRStaffList().then((r) => { });
        getHRStaffData().then((r) => { });
        getHRStaffData().then((r) => { });

    }, []);

    const handlesearch =(e)=>{
        let filtered = props.hrStaffList.filter(x=>
            x.FirstName.toLowerCase().includes(e.target.value.toLowerCase())||
            x.Surname.toLowerCase().includes(e.target.value.toLowerCase())||
            x.MiddleName.toLowerCase().includes(e.target.value.toLowerCase())||
            x.OfficialEmailAddress.toLowerCase().includes(e.target.value.toLowerCase())
            );
        setStaff_List(filtered)
    }


    return loading ? (
        <Loader />
    ) : (

        <>
            <Banner
                Image={bg3}
                Title={"Staff Directorate"}
                breadcrumb={["Academics", "Course Finder"]}
                height="50px"
            />
            <div className="course-archive bg-dark py-3 px-4 mb-5 border-radius">
                <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-md-4">
                        <div className="d-flex">
                            <div className="text-white">
                                showing {staff_list.length} staff
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 mt-4 mt-md-0">
                        <form className="navbar-form search d-flex">
                            <input type="text" name="search" onChange={handlesearch} className="form-control input-search rounded-0 bg-white" placeholder="search" autoComplete="off" />
                            <a href="#"><i className="fa fa-search text-white" /></a>
                        </form>
                    </div>
                </div>
            </div>
            <section className="pb-5">
                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                    <div className="row col-md-12 ">
                        {staff_list
                            .filter((result) => {
                                if (query === "") {
                                    return result;
                                } else if (
                                    result.FirstName.toLowerCase().includes(
                                        query.toLowerCase()
                                    )
                                ) {
                                    return result;
                                }
                            })
                            .map((r, index) => {
                                return (
                                    <div className="col-xl-3 mb-4" key={index}>
                                        <div className="team">
                                            <div className="team-img">
                                                <Link to={`/staff/${r.Slug}`}>
                                                    
                                                    <img className="img-fluid"  
                                                    src={r.Image !== null ? r.Image.includes("simplefileupload") ? r.Image :  `${serverLink}public/uploads/${shortCode}/hr/document/${r.Image}` : ""}
                                                     />
                                                </Link>
                                                <div className="team-info" >
                                                    <Link to={`/staff/${r.Slug}`} className="team-name" style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                        {titles.length > 0 &&
                                                            titles.filter((i) =>
                                                                i.EntryID ===
                                                                r.TitleID
                                                            )
                                                                .map((r) => r.TitleName)} {""}
                                                        {r.FirstName} {r.Surname} {r.MiddleName}

                                                    </Link><br />
                                                    <span style={{ marginTop: "-30px" }} className="team-leader">{designation.length > 0 &&
                                                        designation.filter((i) =>
                                                            i.EntryID ===
                                                            r.DesignationID
                                                        )
                                                            .map((r) => r.DesignationName)}</span>
                                                    <p><small >
                                                        {r.OfficialEmailAddress}
                                                    </small></p>
                                                    <ul className="list-unstyled">
                                                        <li><a target={"_blank"} href="https://facebook.com"><i className="fab fa-facebook-f" /></a></li>
                                                        <li><a target={"_blank"} href="https://twitter.com/i/flow/login"><i className="fab fa-twitter" /></a></li>
                                                        <li><a target={"_blank"} href="https://www.linkedin.com/"><i className="fab fa-linkedin-in" /></a></li>
                                                        <li><a target={"_blank"} href="https://www.instagram.com/"><i className="fab fa-instagram" /></a></li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                        {/* team-01 END */}
                                    </div>

                                )
                            }
                            )}
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        hrStaffList: state.HRStaffListDetails,
        hrStaffData: state.HRStaffDataDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHRStaffListDetails: (p) => {
            dispatch(setHRStaffListDetails(p));
        },
        setOnHRStaffDataDetails: (p) => {
            dispatch(setHRStaffDataDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffList);
