import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
    paystackAPIKey,
    projectAddress,
    projectName,
    projectURL,
    sendEmail,
    serverLink,
    shortCode
} from "../../../../resources/url";
import { usePaystackPayment } from 'react-paystack';
import Sidebar from "./sidebar";

const UGApplicationPreview = (props)=> {
    const navigate = useNavigate();
    const [canSubmit, setCanSubmit] = useState(true);
    const [requiredDoc, setRequiredDoc] = useState(false);
    const [userData] = useState(props.applicationLogin);

    const paystackConfig = {
        // reference: (new Date()).getTime().toString(),
        email: props.applicant_data.applicant_data[0].EmailAddress,
        amount: 200000,
        publicKey: paystackAPIKey,
    };
    // you can call this function anything
    const onPaystackSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        //console.log(reference);
        if (reference.status === 'success' && reference.message === 'Approved') {
            toast.info('Submitting your application. Please wait!');
            const sendData = {
                ApplicationID: props.applicationData.EntryID,
                PaymentID: reference.reference,
                StudentID: props.applicationData.EntryID,
                Description: 'Post UTME Fee Payment',
                Amount: 2000
            }

             axios.post(`${serverLink}application/upload/online/payment`, sendData)
                .then(res => {
                    if (res.data.message === 'success') {
                        handleSubmit();
                    }
                })
                .catch(err => {

                })

        }
    };

    // you can call this function anything
    const onPaystackClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        //console.log('closed')
    }

    const PaystackHook = () => {
        const initializePayment = usePaystackPayment(paystackConfig);
        return (
            <div>
                <button className="btn btn-dark w-100 active" onClick={() => {
                    initializePayment(onPaystackSuccess, onPaystackClose)
                }}>Submit Application</button>
            </div>
        );
    };

    // useEffect(() => {
    //     let check = false;
    //   props.appRequirement.length > 0 &&
    //     props.appRequirement.map( async (req) => {
    //       if (req.IsRequired === 1) {
    //         let check = props.applicant_data.documents.filter(
    //           (doc) => doc.DocumentType === req.DocumentName
    //         );
    //         if (check.length === 0 || check.length < req.MinDocument) {
    //             console.log('true is here')
    //             check = true;
    //         }
    //       }
    //     });
    //   console.log(check)
    // }, []);
  
    const handleSubmit = async () => {
      if (props.applicationData.EntryID) {
        toast.info(`Please wait while submitting your application...`);
        await axios
          .patch(
            `${serverLink}application/pg/update/status/${props.applicationData.EntryID}`
          )
          .then((res) => {
            if (res.data.message === "success") {
              toast.success(`Application submitted successfully`);
  
              sendEmail(
                props.applicant_data.applicant_data[0].EmailAddress,
                `Application for ${props.applicationData.CourseName}`,
                "Application Submitted",
                `${props.applicant_data.applicant_data[0].FirstName} ${props.applicant_data.applicant_data[0].MiddleName} ${props.applicant_data.applicant_data[0].Surname}`,
                `Thank you for applying for a place in the ${props.applicationData.CourseName} programme. <br/>
                  Provided your application is complete and includes the required documents, we aim to give you a decision on your application by the relevant decision date`,
                `Best Regards <br/> Admission Team <br/> <a target="_blank" href=${projectURL}>${projectURL}</a>`
              );
              navigate("/admission/application/dashboard");
            } else {
              toast.error(
                `Something went wrong. Please check your connection and try again!`
              );
            }
          })
          .catch((error) => {
            console.log("NETWORK ERROR", error);
          });
      }
    };
  
    let passport = props.applicant_data.documents.filter((item) =>
      item.DocumentType.includes("Passport")
    );
  
    if (
      props.applicant_data.oLevel.length > 0 &&
      props.applicant_data.guardian.length > 0 &&
      requiredDoc
    ) {
      // onSubmit();
      setCanSubmit(true);
    }
  
    const onPrevious = () => {
      navigate("/admission/application/undergraduate/documents-uploads");
    };
  
    let jamb_total_score = 0;
    return (
        <>
          <div className="graduate-admission pb-70">
            <div className="container">
              <div className="row">
                <Sidebar userData={userData} />
                <div className="col-9">
                  {canSubmit ? (
                    <>
                      <div className="pt-3">
                        <div
                          className="header"
                          style={{
                            backgroundColor: "#e0e0e0",
                            color: "black",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              padding: "12px",
                              color: "black",
                            }}
                          >
                            <h5
                              style={{
                                textAlign: "center",
                                color: "black",
                              }}
                            >
                              {projectName}
                            </h5>
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                marginButton: "50px",
                                lineHeight: "1.6",
                              }}
                            >
                              {projectAddress} <br />
                              Applicant Submission Summary
                            </p>
                          </div>
                        </div>
    
                        <div className="pt-4">
                          <div
                            className=""
                            style={{
                              backgroundColor: "#e0e0e0",
                              color: "black",
                              height: "40px",
                            }}
                          >
                            <h6
                              className="pt-2 p-lg-2"
                              style={{
                                color: "black",
                              }}
                            >
                              Personal Information
                            </h6>
                          </div>
    
                          <div className="row pt-2">
                            <div className="col-lg-3 col-sm-3">
                              <img
                                src={
                                  passport.length > 0
                                    ? `${passport[0].FileName}`
                                    : "https://via.placeholder.com/150"
                                }
                                height={150}
                                alt="Applicant Passport"
                              />
                            </div>
                            <div className="col-lg-9 col-sm-9">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th scope="row">Application ID:</th>
                                    <td>
                                      #
                                      {
                                          props.applicationData.EntryID
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Names</th>
                                    <td>
                                      {
                                        props.applicant_data.applicant_data[0]
                                          .FirstName
                                      }{" "}
                                      {""}
                                      {
                                        props.applicant_data.applicant_data[0]
                                          .MiddleName
                                      }{" "}
                                      {""}
                                      {
                                        props.applicant_data.applicant_data[0]
                                          .Surname
                                      }{" "}
                                      {""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Phone Number</th>
                                    <td>
                                      {
                                        props.applicant_data.applicant_data[0]
                                          .PhoneNumber
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Email</th>
                                    <td>
                                      {
                                        props.applicant_data.applicant_data[0]
                                          .EmailAddress
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
    
                        <div className="pt-3">
                          <div
                            className=""
                            style={{
                              backgroundColor: "#e0e0e0",
                              color: "black",
                              height: "40px",
                            }}
                          >
                            <h6
                              className="pt-2 p-lg-2"
                              style={{
                                color: "black",
                              }}
                            >
                              O'Level Results
                            </h6>
                          </div>
    
                          <div className="pt-4">
                            {props.applicant_data.oLevel ? (
                              <>
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Subjects</th>
                                      <th scope="col">Scores</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {props.applicant_data.oLevel.length > 0 &&
                                      props.applicant_data.oLevel.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td>{item.ExamsSubject}</td>
                                            <td>{item.Score}</td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              </>
                            ) : (
                              <>
                                <div className="alert alert-info">
                                  There is no record.
                                </div>
                              </>
                            )}
                          </div>
                        </div>
    
                        <div className="pt-3">
                          <div
                            className=""
                            style={{
                              backgroundColor: "#e0e0e0",
                              color: "black",
                              height: "40px",
                            }}
                          >
                            <h6
                              className="pt-2 p-lg-2"
                              style={{
                                color: "black",
                              }}
                            >
                              Jamb Results
                            </h6>
                          </div>
    
                          <div className="pt-4">
                            {props.applicant_data.jamb ? (
                              <>
                                  <div className="row">
                                      <h5 className="col-md-6">
                                          Matric Number: {props.applicant_data.jamb[0].MatricNumber}
                                      </h5>
                                      <h5 className="col-md-6">
                                          Examination Year: {props.applicant_data.jamb[0].ExaminationYear}
                                      </h5>
                                  </div>
                                  {
                                      props.applicant_data.jamb[0].SubjectName !== '' &&
                                      <table className="table table-striped">
                                          <thead>
                                          <tr>
                                              <th scope="col">Subjects</th>
                                              <th scope="col">Scores</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {props.applicant_data.jamb.length > 0 &&
                                          props.applicant_data.jamb.map(
                                              (item, index) => {
                                                  jamb_total_score += item.SubjectScore;
                                                  return (
                                                      <tr key={index}>
                                                          <td>{item.SubjectName}</td>
                                                          <td>{item.SubjectScore}</td>
                                                      </tr>
                                                  );
                                              }
                                          )}
                                          </tbody>
                                          <tfoot>
                                          <tr>
                                              <th>Total</th>
                                              <th>{jamb_total_score}</th>
                                          </tr>
                                          </tfoot>
                                      </table>
                                  }
                              </>
                            ) : (
                              <>
                                <div className="alert alert-info">
                                  There is no record.
                                </div>
                              </>
                            )}
                          </div>
                        </div>
    
                        <div className="pt-3">
                          <div
                            className=""
                            style={{
                              backgroundColor: "#e0e0e0",
                              color: "black",
                              height: "40px",
                            }}
                          >
                            <h6
                              className="pt-2 p-lg-2"
                              style={{
                                color: "black",
                              }}
                            >
                              Guardian Information
                            </h6>
                          </div>
    
                          <div className="pt-4">
                            {props.applicant_data.guardian ? (
                              <>
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Name</th>
                                      <td scope="col">
                                        {props.applicant_data.guardian[0].FirstName}{" "}
                                        {
                                          props.applicant_data.guardian[0]
                                            .MiddleName
                                        }{" "}
                                        {props.applicant_data.guardian[0].Surname}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="col">Email Address</th>
                                      <td scope="col">
                                        {
                                          props.applicant_data.guardian[0]
                                            .PhoneNumber
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="col">Phone Number</th>
                                      <td scope="col">
                                        {
                                          props.applicant_data.guardian[0]
                                            .EmailAddress
                                        }
                                      </td>
                                    </tr>
                                  </thead>
                                </table>
                              </>
                            ) : (
                              <>
                                <div className="alert alert-info">
                                  There is no record.
                                </div>
                              </>
                            )}
                          </div>
                        </div>
    
                        <div className="pt-3">
                          {props.applicant_data.documents ? (
                            <>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Document Type</th>
                                    <th>File Name</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {props.applicant_data.documents.length > 0 &&
                                    props.applicant_data.documents.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td>{item.DocumentType}</td>
                                          <td>
                                            <a
                                              target="_blank"
                                              referrerPolicy="no-referrer"
                                              href={`${serverLink}public/uploads/${shortCode}/application/document/${item.FileName}`}
                                            >
                                              <i className="fa fa-file" />
                                            </a>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <>
                              <div className="alert alert-info">
                                There is no record.
                              </div>
                            </>
                          )}
                        </div> 
                        </div>
                      <div className="row mb-5 mt-5 pb-5">
                          <div className="col-lg-12">
                              <span className="badge bg-danger text-white">PS: An online payment will popup where you're expected to make a payment of N2,000 for Post-UTME Screening Fees</span>
                          </div>
                        <div className="col-lg-6 col-sm-6 mb-1">
                          <button
                            className="btn btn-dark btn w-100 active"
                            onClick={onPrevious}
                          >
                            Previous
                          </button>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            {
                                props.applicant_data.payment_data.length > 0 ?
                                    <button
                                        className="btn btn-dark w-100 active"
                                        onClick={handleSubmit}
                                    >
                                        Submit Application
                                    </button> :
                                    <PaystackHook />
                            }

                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="alert alert-info">
                        Form not completed. Kindly complete the application and come
                        back to this page for submission
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <button
                          className="default-btn btn w-100 active"
                          onClick={onPrevious}
                        >
                          Previous
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
}

const mapStateToProps = (state) => {
    return {
      applicant_data: state.GeneralDetails,
      appRequirement: state.ApplicationRequirementDetails,
      applicationData: state.ApplicationDataDetails,
      applicationLogin: state.ApplicationLoginDetails,
    };
  };
  export default connect(mapStateToProps, null)(UGApplicationPreview);

