import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setCourseListDetails, setGeneralDetails } from "../../actions/setactiondetails";
import { serverLink } from "../../resources/url";
import Banner from "../common/banner/banner";
import Loader from "../common/loader/loader";
import bg3 from "../../images/bg/03.jpg";
import CourseContent from "./section/coursecontent";
import Coursefinderside from "./section/coursefinderside";
import DataLoader from "../common/dataLoader/dataLoader";

const CourseFinder = (props)=>{
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [courseList, setCourseList] = useState(
      props.courseList.filter((e) =>
        e.CourseName.toLowerCase().includes(search.toLowerCase())
      )
    );
    const [courses, setCourses] = useState(props.courseList);
  
    const getData = async () => {
      await axios
        .get(`${serverLink}academics/course/view/list`)
        .then((result) => {
          const data = result.data;
          setLoading(false);
          setCourseList(data);
          props.setOnCourseDetails(data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
  
    useEffect(() => {
      getData();
    }, [""]);
    useEffect(() => {
      setCourseList(
        props.courseList.filter((e) =>
          e.CourseName.toLowerCase().includes(search.toLowerCase())
        )
      );
    }, [search]);
  
    const onCourseChange = (e) => {
      const selectedCourse = e.target.value;
      setSearch(selectedCourse);
    };
  
    const handleOnFacultyClick = (e) => {
      setCourseList(props.courseList.filter((item) => item.FacultyCode === e));
    };
  
    const handleOnApplicationTypeClick = (e) => {
      setCourseList(
        props.courseList.filter((item) => item.ApplicationType === e)
      );
    };
    return loading ? (<DataLoader/>) : (
        <>
        <Banner
                Image={bg3}
                Title={"Course Finder"}
                breadcrumb={["Academics", "Course Finder"]}
                height="50px"
            />
            
            <section className="space-ptb">
        <div className="" style={{marginLeft:"20px", marginRight:"20px"}}>
          <div className="row">
          <CourseContent courseList={courseList} />
                <Coursefinderside
                  courseList={courses}
                  onCourseChange={onCourseChange}
                  handleOnFacultyClick={handleOnFacultyClick}
                  handleOnApplicationTypeClick={handleOnApplicationTypeClick}
                />
            {/* item START */}
           
            
          </div>
        </div>
      </section>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
      courseList: state.CourseListDetails,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      setOnGeneralDetails: (p) => {
        dispatch(setGeneralDetails(p));
      },
      setOnCourseDetails: (p) => {
        dispatch(setCourseListDetails(p));
      },
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(CourseFinder);
  
