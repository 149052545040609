import storage from "redux-persist/lib/storage";
import {
  CourseDataDetailsReducer,
  CourseListDetailsReducer,
  DepartmentDataDetailsReducer,
  DepartmentListDetailsReducer,
  EventDetailsReducer,
  FacultyDataDetailsReducer,
  FacultyListDetailsReducer,
  ModuleListDetailsReducer,
  NewsDetailsReducer,
  StaffListDetailsReducer,
  StaffProfileDetailsReducer,
  GeneralDetailsReducer,
  PublicationDetailsReducer,
  UniversityInPressDetailsReducer,
  ApplicationLoginDetailsReducer,
  ApplicationDataDetailsReducer,
  HRStaffListDetailsReducer,
  HRStaffDataDetailsReducer,
  HomepageContentDetailsReducer, ApplicationRequirementDetailsReducer, SliderDetailsReducer, PartnersDetailsReducer,
} from "./detailsReducer";
import { projectCode } from "../resources/url";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  FacultyListDetails: FacultyListDetailsReducer,
  FacultyDataDetails: FacultyDataDetailsReducer,
  DepartmentListDetails: DepartmentListDetailsReducer,
  DepartmentDataDetails: DepartmentDataDetailsReducer,
  CourseListDetails: CourseListDetailsReducer,
  CourseDataDetails: CourseDataDetailsReducer,
  StaffListDetails: StaffListDetailsReducer,
  StaffProfileDetails: StaffProfileDetailsReducer,
  ModuleListDetails: ModuleListDetailsReducer,
  NewsDetails: NewsDetailsReducer,
  EventDetails: EventDetailsReducer,
  ApplicationLoginDetails: ApplicationLoginDetailsReducer,
  ApplicationDataDetails: ApplicationDataDetailsReducer,
  GeneralDetails: GeneralDetailsReducer,
  PublicationDetails: PublicationDetailsReducer,
  UniversityInPress: UniversityInPressDetailsReducer,
  HRStaffListDetails: HRStaffListDetailsReducer,
  HRStaffDataDetails: HRStaffDataDetailsReducer,
  HomepageContentDetails: HomepageContentDetailsReducer,
  ApplicationRequirementDetails: ApplicationRequirementDetailsReducer,
  SliderDetails: SliderDetailsReducer,
  PartnersDetails: PartnersDetailsReducer
});

const persistConfig = {
  key: 'al-ansar-website',
  storage,
};

export default persistReducer(persistConfig, rootReducer);
