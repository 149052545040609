import { Link, useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import axios from "axios";
import { serverLink, shortCode } from "../../../../resources/url";
import { sendEmail } from "../../../../resources/url";
import SimpleFileUpload from "react-simple-file-upload";
import { simpleFileUploadAPIKey } from "../../../../resources/url";

const JobApplicationPage = (props) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(true)
    const user = props.userData;
    const EmailAddress = user.split(',')[0];
    const FirstName = user.split(',')[1] !== "" ? user.split(',')[1] : "";
    const MiddleName = user.split(',')[2] !== "" ? user.split(',')[2] : "";
    const Surname = user.split(',')[3];
    const PhoneNumber = user.split(',')[4];
    const CurriculumVitae = user.split(',')[5]

    const editor = useRef(null)
    const [content, setContent] = useState('')
    const [errorLabel, setErrorLabel] = useState(false)

    const [dt, setFormData] = useState({
        FirstName: FirstName,
        MiddleName: MiddleName,
        Surname: Surname,
        EmailAddress: EmailAddress,
        PhoneNumber: PhoneNumber,
        CoverLetter: "",
        CurriculumVitae: CurriculumVitae,
        Position: props.jobDetails[0]?.Position,
        Faculty: props.jobDetails[0]?.Faculty,
        Department: props.jobDetails[0]?.Department,
        NewCurriculumVitae: "",
        JobID: props.jobDetails[0]?.EntryID,
        //for email
        to: EmailAddress,
        subject: 'JOB APPLICATION',
        title: 'Acknowledgement',
        name: Surname + " " + FirstName,
        body: `<div>We have recieved your application for position of ${props.jobDetails[0]?.Position}.<br/>
        Your Application would be passed through the panel and we would communicate the response to you via the email you provided.<br/>
        Thank you.</div>`,
        signature: '',

    })

    const onEdit = (e) => {
        setFormData({
            ...dt, [e.target.name]: e.target.value
        })
        if (e.target.name === "NewCurriculumVitae") {
            if (e.target.files[0].type !== "application/pdf") {
                setErrorLabel(true);
                toast.error("only pdf documents are allowed...")
            } else {
                setErrorLabel(false);
                setFormData({
                    ...dt,
                    [e.target.name]: e.target.files[0]
                })
            }
        }
    }

    const SubmitApplication = async (e) => {
        e.preventDefault();
        if (props.jobDetails.length > 0) {

            if (dt.CoverLetter === "") {
                toast.error("Please write in your cover letter");
            }
            else if (CurriculumVitae === "" && dt.NewCurriculumVitae === "") {
                toast.error("You do not have your CV saved, please upload cv...");
            }
            else {
                toast.info("submitting, please wait...");
                await axios
                    .post(`${serverLink}jobs/job-openings/applicant/submit_application`, dt)
                    .then((result) => {
                        if (result.data.message === "success") {
                            //setShow(false)
                            const application_id = result.data.application_id

                            toast.success("Application Submitted successfully, we would send you a confirmation email...");
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });

                            //}
                            setFormData({
                                ...dt, CoverLetter: ''
                            })
                            sendEmail(dt.to, dt.subject, dt.title, dt.name, dt.body, dt.signature)

                        }
                        else if (result.data.message === "exist") {
                            toast.error("You have already submitted application for this position");
                            setTimeout(() => {
                                window.location.href = "/careers"
                            }, 2000);
                        }
                        else if (result.data.message === "no cv") {
                            toast.error("You do not have your CV saved, please upload cv...");
                        }
                        else {
                            toast.error("Something went wrong, please try again");
                        }
                    })
                    .catch((error) => {
                        toast.error("Network error!, Try again later...");
                    });
            }
        }
        else {
            navigate('/careers')
        }
    }

    const handleFile = (url) => {
        setFormData({
            ...dt,
            NewCurriculumVitae: url,
        })
    }
    return (
        <>
            {show === true && <div className="register-area pt-100 pb-70" id="apply">
                <div className="container">
                    <div className="register">
                        <h3>Apply</h3>
                        <form onSubmit={SubmitApplication}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="FirstName">First Name</label>
                                        <input
                                            disabled
                                            value={FirstName}
                                            type="text"
                                            name="FirstName"
                                            className="form-control"
                                            placeholder="First Name*"
                                            onChange={onEdit}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="MiddleName">Middle Name</label>
                                        <input
                                            value={MiddleName}
                                            disabled
                                            type="text"
                                            name="MiddleName"
                                            className="form-control"
                                            placeholder="Middle Name"
                                            onChange={onEdit}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="Surname">Surname</label>
                                        <input
                                            disabled
                                            value={Surname}
                                            type="text"
                                            name="Surname"
                                            className="form-control"
                                            placeholder="Surname*"
                                            onChange={onEdit}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="PhoneNumber">Phone Number</label>
                                        <input
                                            disabled
                                            value={PhoneNumber}
                                            type="text"
                                            name="PhoneNumber"
                                            className="form-control"
                                            placeholder="Phone Number*"
                                            onChange={onEdit}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="EmailAddress">Email Address</label>
                                        <input
                                            disabled
                                            value={EmailAddress}
                                            type="email"
                                            name="EmailAddress"
                                            className="form-control"
                                            placeholder="Email Address*"
                                            onChange={onEdit}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="CoverLetter">Cover Letter</label>
                                        <JoditEditor
                                            value={dt.CoverLetter}
                                            tabIndex={1}
                                            onChange={(e) => {
                                                setFormData({
                                                    ...dt, CoverLetter: e
                                                })
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <div>
                                            {
                                                CurriculumVitae !== "" && <a className="link link-info mb-10" target={'_blank'}
                                                    href={CurriculumVitae !== "" ? CurriculumVitae.includes("simplefileupload") ? CurriculumVitae : `${serverLink}public/uploads/${shortCode}/job_application/cv/${CurriculumVitae}` : ""}
                                                >View current cv</a>
                                            }
                                        </div>
                                        <label htmlFor="NewCurriculumVitae">Upload CV
                                            (<small><strong>Leave empty if you wish to apply with saved cv</strong></small>)
                                        </label>
                                        <strong className="text-danger" ><small> &emsp;(File size must not exceed 2mb)</small></strong>
                                        <SimpleFileUpload
                                            maxFileSize={2}
                                            apiKey={simpleFileUploadAPIKey}
                                            tag="olivia-job-application"
                                            onSuccess={handleFile}
                                            preview="false"
                                            width="100%"
                                            height="100"
                                        />
                                    </div>
                                </div>

                            </div>
                            <button disabled={errorLabel === true ? true : false} type="submit" className="btn btn-dark btn w-100 active">
                                Submit Application
                            </button>
                        </form>
                        <Link style={{ float: 'right', textDecoration: 'underline' }} to="/careers">
                            see more jobs
                        </Link>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default JobApplicationPage;