import axios from "axios";
import swal from "sweetalert";


const CryptoJS = require("crypto-js");

const status = "Live";

export const serverLink =
    status === "Dev"
        ? "http://localhost:4480/"
        : "https://smartsourcingapi.com:5003/";

export const paystackAPIKey = "pk_live_5e3596837a61d6eedd7a5e630d642e14cca3cd63";
export const simpleFileUploadAPIKey = "305e0ddf925a3fc5dd34d314f8230349";
export const projectName = "Al-Ansar University";
export const projectCode = "BABA_AHMED_UNIVERSITY";
export const serverStatus = status;
export const projectURL = "https://aum.edu.ng/";
export const projectPhone = "+234 803 077 7310";
export const projectEmail = "info@aum.edu.ng";
export const projectAddress = "Maiduguri, Borno State Nigeria.";
export const projectStudentURL = "https://student.aum.edu.ng";
export const projectStaffURL = "https://staff.aum.edu.ng";
export const projectPaymentURL = "https://payment.aum.edu.ng";
export const projectLogo = "https://aum.edu.ng/logo.png";
export const projectViceChancellor = ""
export const shortCode = "AUM"
export const schoolName = "Al-Ansar University, Maiduguri";


// SOCIAL MEDIA
export const projectFacebook = "https://www.facebook.com/";
export const projectTwitter = "https://www.twitter.com/";
export const projectInstagram = "https://instagram.com/?lang=en";
export const projectLinkedIn = "https://linkedin.com/?lang=en";
export const projectYoutube = "https://www.youtube.com/u";


export function showAlert(title, msg, type){
  return swal({
      title: title,
      text: msg,
      icon: type,
      button: "OK",
  })
}


export function encryptData(string, val = false) {
  let secret_key = val === false ? "SayyoFilms" : projectCode;
  let secret_iv = val === false ? "FilmsInternational" : projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

  let result = decrypted.toString();
  return btoa(result);
}

let domain = "@aum.edu.ng";
export const projectDomain = domain;

export function decryptData(string, val = false) {
  let secret_key = val === false ? "SayyoFilms" : projectCode;
  let secret_iv = val === false ? "FilmsInternational" : projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatDateAndTime = (date, option) => {
  if (date !== null) {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();

    return date_string;
  } else {
    return "--";
  }
};

export const sendEmail = (email, subject, title, name, body, signature) => {
  const sendEmail = {
    logo: projectLogo,
    from: projectEmail,
    to: email,
    subject: subject,
    title: title,
    name: name,
    body: body,
    signature: signature,
  };
  axios.post(`${serverLink}send_email/send`, sendEmail).then((r) => {
    //console.log("email sent");
  });

  return "sent";
};

var th_val = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
var dg_val = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
var tn_val = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
var tw_val = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

export function convertNumbertoWords(s) {
  s = s.toString();
  s = s.replace(/[\, ]/g, '');
  if (s != parseFloat(s))
    return 'not a number ';
  var x_val = s.indexOf('.');
  if (x_val == -1)
    x_val = s.length;
  if (x_val > 15)
    return 'too big';
  var n_val = s.split('');
  var str_val = '';
  var sk_val = 0;
  for (var i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 == 2) {
      if (n_val[i] == '1') {
        str_val += tn_val[Number(n_val[i + 1])] + ' ';
        i++;
        sk_val = 1;
      } else if (n_val[i] != 0) {
        str_val += tw_val[n_val[i] - 2] + ' ';
        sk_val = 1;
      }
    } else if (n_val[i] != 0) {
      str_val += dg_val[n_val[i]] + ' ';
      if ((x_val - i) % 3 == 0)
        str_val += 'Hundred ';
      sk_val = 1;
    }
    if ((x_val - i) % 3 == 1) {
      if (sk_val)
        str_val += th_val[(x_val - i - 1) / 3] + ' ';
      sk_val = 0;
    }
  }
  if (x_val != s.length) {
    var y_val = s.length;
    str_val += 'point ';
    for (var i = x_val + 1; i < y_val; i++)
      str_val += dg_val[n_val[i]] + ' ';
  }
  return str_val.replace(/\s+/g, ' ');
}

export const currencyConverter = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  });
  return formatter.format(amount)
}

export const EmailTemplates = (type, details) => {
  if (type === '1') {
    const message = {
      subject: 'password recovery',
      title: '',
      body: `<div style="line-height: 1.6">Your job application portal password is ${details.Password} <br/><br/>For further enquiries, please contact <b/>${projectEmail}<br/>${projectPhone}</div>`
    }
    return message;
  }
  else if (type === '2') {
    const message = {
      subject: 'Admission',
      title: 'Password Recovery',
      body: `<div style="line-height: 1.6">
      You have requested to reset your password, kinndly follow this <a href='${projectURL}admission/application/recover-password/${details}'>link</a> to reset your password.
       <br/><br/>For further enquiries, please contact <b/>${projectEmail}<br/>${projectPhone}
      </div>`
    }
    return message;
  }
};

export const visionStatement =
  `The vision of Al-Ansar University is "to be a world-class academic institution where faith, innovation and service are nurtured for the advancement of human development."`;
export const missionStatement =
  `The mission of Al-Ansar University is “to be a beacon of knowledge and transformative learning seeking to advance professionalism, build a diverse and socially inclusive society, and be a powerhouse for human capital development."`;
export const historyStatement = "";
