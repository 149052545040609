import React from "react";
import { serverLink, shortCode } from "../../../resources/url";
import { Link } from "react-router-dom";
import {connect} from "react-redux";

function Slider(props) {
  return (
      <section className="banner position-ralative">
        <div id="main-slider" className="swiper-container h-800 h-lg-700 h-md-600 h-sm-400">
          <div className="swiper-wrapper">

            {
              props.slider.length > 0 &&
              props.slider.map((sl, i) => {
                return <div key={i} className="swiper-slide align-items-center d-flex bg-overlay-black-40" style={{backgroundImage: `url(${sl.ImagePath.includes('simplefileupload.com') ? sl.ImagePath : `${serverLink}public/uploads/${shortCode}/sliders_uploads/${sl.ImagePath}`})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
                  <div className="swipeinner container">
                    <div className="row justify-content-center text-center">
                      <div className="col-lg-10 col-md-12">
                        <div className={`slider-${(i+1)}`}>
                          <div className="animated" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="0.25s">
                            <h1 className="animated text-white mb-4" data-swiper-animation="fadeInUp" data-duration="1.5s"
                                data-delay="0.25s">{sl.Title}</h1>
                            <div className="animated text-white px-lg-5" data-swiper-animation="fadeInUp"
                                 data-duration="2.5s" data-delay="0.25s">
                              <p className="d-none d-sm-block">{sl.Caption}</p>
                            </div>
                            <Link to={sl.BtnLink} className="animated4 btn btn-primary mt-4 ml-1 ml-sm-2"
                               data-swiper-animation="fadeInUp" data-duration="3.5s" data-delay="0.25s">{sl.BtnName}<span/></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              })
            }
          </div>
        </div>

        <div className="swiper-button-prev text-white"><i className="fa fa-arrow-left"/></div>
        <div className="swiper-button-next text-white"><i className="fa fa-arrow-right"/></div>
      </section>
  );
}

const mapStateToProps = (state) => {
    return {
        slider: state.SliderDetails
    };
};

export default connect(mapStateToProps, null)(Slider);
