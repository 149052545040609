import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import NewsDetailsContent from "./section/newsdetailscontent";
import {
  setGeneralDetails,
  setPublicationDetails,
} from "../../../actions/setactiondetails";
import bg3 from "../../../images/bg/03.jpg";
import Banner from "../../common/banner/banner";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { useParams } from "react-router-dom";
import Loader from "../../common/loader/loader";

function NewsDetails(props) {
  const [isLoading, setIsLoading] = useState(true)
  const params = useParams();
  const [newsData, setNewsData] = useState(props.newsData !== undefined ? props.newsData : {});
  const [newsList, setNewsList] = useState(props.newsList.length > 0 ? props.newsList : []);
  const [change, setChange] = useState("");

  useEffect(() => {
    getData();
  }, [change]);

  const changeScreen = (items) => {
    setChange(items);
  };

  const getData = async () => {
    await axios
      .get(`${serverLink}news/list/all/General`)
      .then((result) => {
        const data = result.data;
        const dt = data.length > 0 ? data.filter(x => x.Slug === params.slug) : [];
        setNewsData(dt[0])
        setNewsList(dt);
        setIsLoading(false)
      })
      .catch((error) => {
        console.log("error", error);
      });
  };


  return isLoading ? (<Loader />) : (
    <>
      <Banner
        Image={bg3}
        Title={"News Details"}
        breadcrumb={["Home", "News Details"]}
      />

      <section className="space-ptb" />
      <div className="news-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            {
              newsList.length > 0 &&
              <NewsDetailsContent  IsNews={props.IsNews} 
                setGeneralDetails={props.setOnGeneralDetails}
                channgeScreen={changeScreen}
              />
            }

          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    newsData: state.GeneralDetails,
    newsList: state.NewsDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);
