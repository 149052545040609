import React from "react";
import { Link } from "react-router-dom";
import { formatDateAndTime, serverLink, shortCode } from "../../../../resources/url";

export default function NewsContent(props) {
  const topClicked = async (item) => {
    const newsData = { ...item };
    await props.setGeneralDetails(newsData);
  };
  const bottomClicked = async (item) => {
    const newsData = { ...item };
    props.setGeneralDetails(newsData);
  };
 
  return (
    <section className="mb-5" >
      <div className="container">
        <div className="course-archive bg-dark py-3 px-4 mb-5 border-radius">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-md-8">
              <div className="d-flex">
                <div className="mr-3">
                  <span className="text-white" ><i className="fa fa-th-large mr-2" /></span>
                </div>
                <div className="text-white">
                  Showing {props.newsList.length} News
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-4 mt-md-0">
              <form className="navbar-form search d-flex">
                <input type="text" name="q" className="form-control input-search rounded-0 bg-white" onChange={props.handleSearch} placeholder="search" autoComplete="off" />
                <a href="#"><i className="fa fa-search text-white" /></a>
              </form>
            </div>
          </div>
        </div>
        {
          props.newsList.length > 0 ? (
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="events">
                      {
                        props.newsList.map((item, key) => {
                          return (
                            <div className="row mb-5" key={key}>
                              <div className="col-12">
                                <div className="events shadow p-4 bg-white border-radius">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <div className="events-img mb-4 mb-lg-0" >
                                        <Link to={`/news/${item.Slug}`}>
                                          <img style={{ width: "100%", height: "180px" }} className="img-fluid border-0"
                                            src={item.ImagePath !== null ? item.ImagePath.includes("simplefileupload") ? item.ImagePath : `${serverLink}public/uploads/${shortCode}/news_uploads/${item.ImagePath}` : ""}
                                            onClick={() => bottomClicked(item)}
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 align-self-center">
                                      <div className="events-content">
                                        <div className="events-tag">
                                          <a href="#" className="badge-danger mb-2">{item.FacultyCode}</a>
                                        </div>
                                        <Link to={`/news/${item.Slug}`}>
                                          <p className="text-dark h5" onClick={() => bottomClicked(item)}>
                                            {item.Title}
                                          </p>
                                        </Link>
                                        <div className="events-meta my-2">
                                          <ul className="list-unstyled mb-0 d-flex">
                                            <li className="mr-3"><a href="#"><i className="fas fa-calendar-alt pr-1"></i> {formatDateAndTime(item.InsertedDate, "date")}</a></li>
                                            <li><a href="#"><i className="fas fa-clock pr-1"></i> {item.FacultyCode}</a></li>
                                          </ul>
                                        </div>
                                        <Link to={`/news/${item.Slug}`}>
                                          <p className="text-dark mb-lg-0" onClick={() => bottomClicked(item)}>
                                            {item.Title}
                                          </p>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="col-lg-3 align-self-center text-lg-right text-left">
                                      <Link
                                        to={`/news/${item.Slug}`}
                                        className="btn btn-primary btn-round"
                                      >
                                        <span onClick={() => bottomClicked(item)}>
                                          {" "}
                                          Read More
                                          <i className="flaticon-next" />{" "}
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : <></>
        }
      </div>
    </section>
  );
}
