import Banner from "../../../common/banner/banner"
import bg3 from "../../../../images/bg/03.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { decryptData, EmailTemplates, encryptData, formatDate, formatDateAndTime, projectEmail, projectPhone, sendEmail, serverLink } from "../../../../resources/url";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import JobApplicationPage from "./applicationpage";
import JobLogin from "./login-modal";
import Modal from "../../../common/modal/modal";
import * as DOMPurify from 'dompurify';




const JobDetails = (props) => {
    const { slug } = useParams();
    const navigate = useNavigate();

    const job_id = decryptData(slug)
    const [loading, setLoading] = useState(true);
    const [errorLabel, setErrorLabel] = useState(false)
    const [FacultyList, setFacultyList] = useState([])
    const [DepartmentList, setDepartmentList] = useState([])

    const [jobDetails, setJobDetails] = useState([])
    const [sorted, setSortedOpenings] = useState([])
    const [formType, setFormType] = useState("")

    const [account, setAccount] = useState({
        EmailAddress: "",
        PhoneNumber: "",
        FirstName: "",
        MiddleName: "",
        Surname: "",
        Password: "",
        ConfirmPassword: "",
        ePassword: "",
        RegisterWithoutCV: "",
        RegisterWithCV: ""

    })

    const showRecoverPassword = () => {
        setFormType("Recover")
    }

    const formToggle = () => {
        if (formType === "Login") {
            setFormType("Register")
        }
        else {
            setFormType("Login")
        }
    }

    const getJobDetails = async () => {
        await axios
            .get(`${serverLink}jobs/job-openings/list`)
            .then((result) => {
                const data = result.data;
                const filtered = data.filter(item => item.EntryID.toString() === job_id.toString())
                const sorted = data.sort(function (a, b) {
                    return b.ViewCount - a.ViewCount;
                });
                setJobDetails(filtered)
                setSortedOpenings(sorted)
            })
            .catch((error) => {
                console.log("netwrok error", error);
            });

        await axios.get(`${serverLink}jobs/faculty/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setFacultyList(result.data)
                }
            }).catch((error) => {
                console.log("netwrok error", error);
            });

        await axios.get(`${serverLink}jobs/department/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setDepartmentList(result.data)
                    setLoading(false);
                }
            }).catch((error) => {
                console.log("netwrok error", error);
            });
    };

    const updateViewCount = async () => {
        await axios
            .post(`${serverLink}jobs/job-openings/update_view_count/${parseInt(job_id)}`)
            .then((result) => {

            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getJobDetails();
        updateViewCount();
    }, []);

    const onEdit = (e) => {
        setAccount(
            { ...account, [e.target.name]: e.target.value }
        )
        if (e.target.name === "Password") {
            setAccount({
                ...account,
                "ePassword": encryptData(e.target.value),
                Password: e.target.value
            })
        }
        if (e.target.name === "RegisterWithCV") {
            if (e.target.files[0].type !== "application/pdf") {
                setErrorLabel(true);
                toast.error("only pdf documents are allowed...")
            }
            else {
                setErrorLabel(false);
                setAccount({
                    ...account, [e.target.name]: e.target.files[0]
                })
            }
        }
        if (e.target.name === "Email") {
            setAccount({
                [e.target.name]: e.target.value
            })
        }
    }
    const sendRecoveryMail = async (e) => {
        e.preventDefault();
        try {
            if (account.Email === "") {
                toast.error("Please enter your email")
            } else {
                await axios.get(`${serverLink}jobs/password_recovery/${account.Email}`)
                    .then((result) => {
                        if (result.data.length > 0) {
                            let data = decryptData(result.data[0].Password);
                            const fData = {
                                EmailAddress: account.Email,
                                FirstName: result.data[0].FirstName,
                                Password: data

                            }
                            let email = EmailTemplates('1', fData);
                            sendEmail(
                                account.Email,
                                email.subject,
                                email.title,
                                result.data[0].FirstName,
                                email.body,
                                '',
                                ''
                            )
                            toast.success('An email have been sent to your email address, check to proceed...')
                        }
                    })
            }
        } catch (e) {
            console.log('NETWORK ERROR')
        }
    }
    const ValidatePassword = (e) => {
        setAccount({
            ...account, [e.target.name]: e.target.value
        })
        if (e.target.value.toString() === account.Password.toString()) {
            setErrorLabel(false)
        } else {
            toast.error('Password does not match')
            setErrorLabel(true)
        }
    }

    const Register = async (e) => {
        e.preventDefault();
        toast.info("creating account, please wait...");
        await axios
            .post(`${serverLink}jobs/job-openings/applicant/create_account`, account)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Account created successfully, proceed to login...");
                    setTimeout(() => {
                        document.getElementById("omodal").click()
                    }, 500);
                    setAccount({
                        ...account,
                        PhoneNumber: "",
                        FirstName: "",
                        MiddleName: "",
                        Surname: "",
                        Password: "",
                        ConfirmPassword: "",
                        RegisterWithCV: ""
                    });
                    // }

                } else if (result.data.message === "exist") {
                    toast.error("Account already created, proceed to login");
                } else {
                    toast.error("Something went wrong, please try again");
                }
            })
            .catch((error) => {
                toast.error("Network error!, Try again later...");
            });
    }
    const Login = async (e) => {
        e.preventDefault();
        toast.info("Logging in, please wait...");
        await axios
            .post(`${serverLink}jobs/job-openings/applicant/login`, account)
            .then((result) => {
                if (result.data.message === "success") {
                    setAccount({
                        ...account,
                        EmailAddress: "",
                        Password: "",
                    });
                    const user = result.data.userData[0]
                    const userData = user.EmailAddress + "," + user.FirstName + "," + user.MiddleName + "," + user.Surname + "," + user.PhoneNumber + "," + user.CurriculumVitae
                    window.sessionStorage.setItem("userData", userData)
                    toast.success("Login successful");
                    setTimeout(() => {
                        document.getElementById("omodal").style.visibility = 'hidden';
                        document.getElementById('apply').scrollIntoView(true)
                    }, 500);
                } else {
                    toast.error("wrong login details");
                }
            })
            .catch((error) => {
                toast.error("Network error!, Try again later...");
            });

    }

    const handleFile = (url) => {
        setAccount({
            ...account, RegisterWithCV: url
        })
    }

    return (
        <>
            <Banner
                Image={bg3}
                Title={jobDetails[0]?.Position}
                breadcrumb={[
                    DepartmentList.filter(x => x.DepartmentCode === jobDetails[0]?.Department)[0]?.DepartmentName,
                    jobDetails[0]?.Position]}
            />

            <div className="news-details-area pt-100 pb-70 mt-5 mb-10">
                <div className="container">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 mb-5">
                                    <div className="events">

                                        <div className="events-content mt-4">
                                            <div className="d-flec justify-content-between">
                                                <p className="text-dark h6">{jobDetails[0]?.Position}</p>

                                            </div>
                                            <hr />
                                            <div className="how-to-apply">
                                                <h3>Job Description</h3>
                                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jobDetails[0]?.Description) }}>
                                                </p>
                                                <h3>Requirements</h3>
                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jobDetails[0]?.Requirements) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-5 mb-lg-0">
                                    <div className="blog-sidebar">
                                        <div className="widget">
                                            <div className="widget-title">
                                                <h5>Search</h5>
                                            </div>
                                            <form className="navbar-form search d-flex">
                                                <input type="text" name="q" className="form-control input-search rounded-0 bg-white" placeholder="Search" autoComplete="off" />
                                                <a><i className="fa fa-search text-white" /></a>
                                            </form>
                                        </div>
                                        <br /><hr />

                                        <div className="additional-information">
                                            <h5  >Apply Now</h5>
                                            {jobDetails[0]?.Status === '1' && (
                                                <div className="alert alert-warning">
                                                    {formatDateAndTime(formatDate(jobDetails[0].ClosingDate), "date")}
                                                </div>
                                            )}
                                            <div className="list">
                                                <div>
                                                    {window.sessionStorage.getItem("userData") ?
                                                        <div className="btn btn-sm btn-primary w-100" onClick={() => {
                                                            document.getElementById('apply').scrollIntoView(true)
                                                        }}
                                                        >
                                                            Apply
                                                        </div>

                                                        :
                                                        <a className="btn btn-sm btn-primary w-100" onClick={() => {
                                                            setFormType('Login')
                                                        }} href="#omodal">
                                                            Login to Apply
                                                        </a>
                                                    }
                                                    <Link to={"/careers"}>
                                                        <div className="btn btn-sm btn-info w-100 mt-3">
                                                            <i className="fa fa-arrow-circle-o-left fa-lg" />&emsp;Back to Jobs</div>
                                                    </Link>
                                                    {window.sessionStorage.getItem("userData") &&
                                                        <div className="btn btn-sm btn-dark w-100 mt-3" onClick={() => {
                                                            window.sessionStorage.removeItem("userData");
                                                            // toast.success('Logout successful')
                                                            navigate('/careers')
                                                        }} >
                                                            Logout
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <br /><hr />
                                        <div className="related-download">
                                            <h5>Contact Us</h5>
                                            <ul>
                                                <li>
                                                    <a href={`tel:${projectPhone}`}>
                                                        <i className="flaticon-phone-call" />
                                                        {projectPhone}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={`mailto:${projectEmail}`}>
                                                        <i className="flaticon-email" />
                                                        Send us mail
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {window.sessionStorage.getItem("userData") &&
                                <div>
                                    {
                                        jobDetails.length > 0 &&
                                        <JobApplicationPage
                                            userData={window.sessionStorage.getItem("userData")}
                                            jobDetails={jobDetails}
                                        />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <JobLogin
                modalid="omodal"
                formType={formType}
                formToggle={formToggle}
                onEdit={onEdit}
                Login={Login}
                Register={Register}
                errorLabel={errorLabel}
                ValidatePassword={ValidatePassword}
                showRecoverPassword={showRecoverPassword}
                sendRecoveryMail={sendRecoveryMail}
                handleFile={handleFile}
            />
        </>
    )
}

export default JobDetails;