import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PageName from "../common/pagename/pagename";
import Banner from "../common/banner/banner";
import bg from "../../images/bg/03.jpg";
import { missionStatement, visionStatement } from "../../resources/url";
import * as DOMPurify from 'dompurify';



function About(props) {
    return (
        <>
            <PageName page={"About Us"} />
            <Banner
                Image={bg}
                Title={"About Us"}
                breadcrumb={["Home", "About Us"]}
            />
            <div className="academics-details-area pt-4 mb-5 pb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="academics-details">
                                <div className="details">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(props.homeData.AboutContent),
                                        }} style={{ textAlign: 'justify' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">

                            <div className="course-sidebar shadow">
                                <div className="widget">
                                    <div className="widget-title">
                                        <h5>Additional Information</h5>
                                    </div>
                                    <div className="widget-categories">
                                        <ul className="list-unstyled list-style mb-0">
                                            <li>
                                                <Link to={`/our-campus`}>
                                                    Our Campus
                                                    <i className="fa fa-angle-right float-right" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admission/course-finde`}>
                                                    Courses
                                                    <i className="fa fa-angle-right float-right" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/admission/application`}>
                                                    Application
                                                    <i className="fa fa-angle-right float-right" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/education`}>
                                                    Education
                                                    <i className="fa fa-angle-right float-right" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/news`}>
                                                    News
                                                    <i className="fa fa-angle-right float-right" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="accordion" id="career-opportunities">
                            <div className="card">
                                <div className="accordion-icon card-header" id="headingOne">
                                    <h4 className="mb-0">
                                        <button className="btn" type="button" data-toggle="collapse"
                                            data-target="#security-manager" aria-expanded="true"
                                            aria-controls="security-manager">
                                            Our Mission
                                        </button>
                                    </h4>
                                </div>
                                <div id="security-manager" className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#career-opportunities">
                                    <div className="card-body">
                                        {missionStatement}
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-5">
                                <div className="accordion-icon card-header" id="headingTwo">
                                    <h4 className="mb-0">
                                        <button className="btn" type="button" data-toggle="collapse"
                                            data-target="#vision" aria-expanded="false"
                                            aria-controls="vision">
                                            Our Vision
                                        </button>
                                    </h4>
                                </div>
                                <div id="vision" className="collapse show"
                                    aria-labelledby="headingTwo"
                                    data-parent="#career-opportunities">
                                    <div className="card-body">
                                        {visionStatement}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(About);