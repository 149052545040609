import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import NavigationBar from "../common/navigation/navigationbar";
import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import Homepage from "../homepage/homepage";
import Footer from "../common/footer/footer";
import NotFound from "../404notfound/404notfound";
import Events from "../resources/event";
import EventDetails from "../resources/event-details";
import ContactUs from "../contact-us/contact-us";
import ApplicantLogin from "../admission/application/loginpage/login";
import GuardianInformation from "../admission/application/undergraduate/GuardianInformation";
import ApplicationLandingPage from "../admission/application/landingpage/applicationlandingpage";
import DocumentUpload from "../admission/application/undergraduate/DocumentUpload";
import UGApplicationPreview from "../admission/application/undergraduate/document-preview";
import CourseFinder from "../coursefinder/coursefinder";
import ApplicationRecoverPassword from "../admission/application/loginpage/recover-passwords";
import PersonalInformation from "../admission/application/undergraduate/personal-information";
import UndergraduateApplicationDashboard from "../admission/application/undergraduate/dashboard";
import Selection from "../admission/application/undergraduate/selection";
import Olevel from "../admission/application/undergraduate/Olevel";
import Jamb from "../admission/application/undergraduate/jamb";
import FacultyPage from "../academics/section/facultynews";
import ApplicationDashboard from "../admission/application/dashboard/applicationdashboard";
import ApplicationRegistration from "../admission/application/registrationpage/registrationpage";
import News from "../resources/news/news";
import NewsDetails from "../resources/news/newsdetails";
import Coursedetails from "../coursedetails/coursedetails";
import StaffList from "../staff-list/staff-list";
import LearningGoals from "../academics/learning-goals";
import AcademicRegistry from "../academics/academic-registery";
import AcademicCalender from "../academics/academic-calender";
import Board from "../about/leadership/board";
import Senate from "../about/leadership/senate";
import Management from "../about/leadership/management";
import Gallery from "../about/gallery/gallery";
import Fees from "../admission/fees/fees";
import About from "../about/about";
import OurCampus from "../about/our-campus";
import StaffDetails from "../staff-list/staff-details";
import FeeDetails from "../admission/fees/fee-details";
import JobOpenings from "../resources/jobs/job-openings/job-opening";
import ComingSoon from "../common/coming-soon";
import GoverningCouncil from "../about/leadership/governing-council";
import JobDetails from "../resources/jobs/job-openings/job-details";
import ChangePassword from "../admission/application/loginpage/recover-passwords";
import NewStudentEnrolment from "../admission/application/new-student-enrolment/new-student-enrolment";
import StaffDetFromBarcode from "../staff-list/staff-det-from-barcode";
import Library from "../library/library";
import TheFounder from "../about/leadership/the-founder";

export default function PageRoutes() {
  return (
    <Routers>
      <NavigationBar />

      <Routes>
        <Route path={"/"} element={<Homepage />} />

        <Route path={"/about-us"} element={<About/>} />
        <Route path={"/our-campus"} element={<OurCampus/>} />
        <Route path={"/gallery"} element={<Gallery/>} />
        <Route path={"/board"} element={<Board/>} />
        <Route path={"/governing-council"} element={<GoverningCouncil/>} />
        <Route path={"/management"} element={<Management/>} />
        <Route path={"/senate"} element={<Senate/>} />
        <Route path="/the-founder" element={<TheFounder />} />

        <Route path={"/admission"} element={""} />
        <Route path={"/course-finder"} element={<CourseFinder/>} />
        <Route path={"/course/:slug"} element={<Coursedetails/>} />
        <Route path={"/apply-now"} element={<ApplicationLandingPage/>} />
        <Route path={"/fees"} element={<Fees/>} />
        <Route path={"/fees/:slug"} element={<FeeDetails/>} />

        <Route path="/faculty/:slug" element={<FacultyPage />} />

        <Route path={"/staff-directorate"} element={<StaffList/>} />
        <Route path="/staff/:slug" element={<StaffDetails/>}/>
        <Route path={"/academic-registry"} element={<AcademicRegistry/>} />
        <Route path={"/learning-goal"} element={<LearningGoals/>} />
        <Route path={"/academic-calender"} element={<AcademicCalender/>} />
        <Route exact path="/details" element={<StaffDetFromBarcode />} />

        <Route path={"/news"} element={<News/>} />
        <Route path={"/news/:slug"} element={<NewsDetails IsNews={true} />} />

        <Route path={"/events"} element={<Events/>} />
        <Route path={"/events/:slug"} element={<EventDetails/>} />
        <Route path={"/publication"} element={""} />
        <Route path={"/careers"} element={<JobOpenings/>} />
        <Route path={"/careers/job-details/:slug"} element={<JobDetails/>} />

        <Route path={"/contact-us"} element={<ContactUs />} />
        <Route path="/coming-soon" element={<ComingSoon/>} />
        <Route path="/library" element={<Library/>} />

        

        {/*Application End*/}
        <Route path={"*"} element={<NotFound />} />
        <Route path="/admission/application/login" element={<ApplicantLogin/>}  />
        <Route path="/admission/application/register" element={<ApplicationRegistration/>}  />
        <Route path="/admission/application/dashboard" element={<ApplicationDashboard/>}  />


        <Route path="/admission/application/undergraduate/guardian-information" element={<GuardianInformation/>} />

        <Route path="/admission/application" element={<ApplicantLogin/>}  />
        <Route path="/admission/application/recover-password" element={<ApplicationRecoverPassword/>} />
        <Route path="/admission/application/recover-password/:slug" element={<ChangePassword/>} />
        <Route path="/admission/application/undergraduate/guardian-information" element={<GuardianInformation/>} />
        <Route path="/admission/application/undergraduate/documents-uploads" element={<DocumentUpload/>} />
        <Route path="/admission/application/undergraduate/document-preview" element={<UGApplicationPreview/>} />
        <Route path="/admission/application/undergraduate/personal-information" element={<PersonalInformation/>} />
        <Route path="/admission/application/undergraduate" element={<UndergraduateApplicationDashboard/>} />
        <Route path="/admission/application/undergraduate/selection" element={<Selection/>} />
        <Route path="/admission/application/undergraduate/secondary-school-result" element={<Olevel/>} />
        <Route path="/admission/application/undergraduate/jamb-result" element={<Jamb/>} />
        <Route path="/admission/application/enrolment" element={<NewStudentEnrolment/>} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />

      <Footer />
    </Routers>
  );
}
