import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    formatDateAndTime,
    projectEmail,
    projectPhone,
    serverLink,
} from "../../../../resources/url";
import Loader from "../../../common/loader/loader";
import axios from "axios";
import Banner from "../../../common/banner/banner";
import bg3 from "../../../../images/bg/09.jpg";
import DataLoader from "../../../common/dataLoader/dataLoader";
import AUM_Call from './AUM_notice.pdf'


function ApplicationLandingPage(props) {
    const [dateline, setDateline] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDateline = async () => {
        await axios
            .get(`${serverLink}application/dateline`)
            .then((res) => {
                setDateline(res.data.data[0]);
                setLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR", err);
            });
    };

    useEffect(() => {
        getDateline();
    }, []);
    return loading ? (
        <DataLoader />
    ) : (
        <>
            <Banner
                Image={bg3}
                Title={"Application Guide"}
                breadcrumb={["Admission", "ApplicationGuide"]}
                height="50px"
            />

            <div className="mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8" >
                            <div className="">
                                <h4 style={{ color: "maroon" }}>Public Notice</h4>
                                <p>
                                    Further to our advertisement for the commencement of academic activities, we are pleased to announce that the University is set to commence academic Programmes in the College of Medicine and Health Sciences and two other Programmes in the College of Science Information and Communication Technology during the 2022/2023 academic session. Qualified candidates are therefore invited to apply for admission taking note of:
                                </p>
                                <h4 style={{ color: "maroon" }}>Eligibility</h4>
                                <ol className="mb-3 text-justify">
                                    <li>
                                        <p>
                                            Candidates must choose AUM as their most preferred institution and have a minimum cut off mark of 140 in 2021 and 2022 UTME subject combination respectively conducted by the Joint Admissions and Matriculation Board (JAMB); and
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Candidates must have five (5) Credits, including English Language and Mathematics in WAEC/NECO at not more than two (2) sittings.
                                        </p>
                                    </li>
                                </ol>


                                The SSCE Credit passes and UTME Subject Combinations for each of the Undergraduate Programs in the three (3) Colleges are as follows:
                                <div className="mb-3 mt-3">
                                    <strong>
                                        <a className="text-decoration-underline" href={"https://aum.edu.ng/AUM_20232024_UTME_&_DE_ADMISSION_REQUIREMENTS.pdf"} target={"_blank"} >Click to download admission requirements</a>
                                    </strong>
                                </div>
                                {/* <div className="table table-responsive-sm">
                                    <table className="table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <td>Faculty</td>
                                                <td>SSCE Requirement</td>
                                                <td>UTME Requirement</td>
                                                <td>UTME Cut Off</td>
                                                <td>Fees</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ backgroundColor: '#dee2e6' }}>
                                                <td colSpan={5}>COLLEGE OF ARTS, MANAGEMENT AND SOCIAL SCIENCES</td>
                                            </tr>
                                            <tr>
                                                <td>Arabic<br />
                                                    B. A. Arabic
                                                </td>
                                                <td>Five Credits at SSCE in English, Mathematics, IRS, & any other two relevant Subject at not more than two sittings</td>
                                                <td>English, Arabic & any two others from Arts & Social Sciences subject.</td>
                                                <td>140</td>
                                                <td>N210,000:00 (without accommodation)</td>
                                            </tr>
                                            <tr>
                                                <td>Islamic Studies<br />
                                                    B. A. Islamic Studies
                                                </td>
                                                <td>Five Credits at SSCE in English, Mathematics, IRS, & any other two relevant Subject at not more than two sittings.</td>
                                                <td>English, IRS and any other two Arts or Social Science Subjects.</td>
                                                <td>140</td>
                                                <td>N210,000:00 (without accommodation)</td>
                                            </tr>
                                            <tr>
                                                <td>Accounting<br />
                                                    B. Sc. Accounting
                                                </td>
                                                <td>Five Credits at SSCE including English, Mathematics, Economics/Accounting & any other two Social Science Subjects at not more than two sitting.</td>
                                                <td>English, Mathematics, Economics/Accounting & any other Arts or Social Science Subject.</td>
                                                <td>140</td>
                                                <td>N750,000:00 (without accommodation)</td>
                                            </tr>


                                            <tr style={{ backgroundColor: '#dee2e6' }}>
                                                <td colSpan={5}>COLLEGE OF MEDICINE AND HEALTH SCIENCES</td>
                                            </tr>
                                            <tr>
                                                <td>COLLEGE OF MEDICINE AND HEALTH SCIENCES</td>
                                                <td>Five Credits at SSCE including English Language Mathematics, Physics, Biology and Chemistry at not more than two sittings.</td>
                                                <td>English, Mathematics, Chemistry, Biology or Physics</td>
                                                <td>140 points</td>
                                                <td>N1,200,000.00 (Without accommodation)</td>
                                            </tr>

                                            <tr style={{ backgroundColor: '#dee2e6' }}>
                                                <td colSpan={5}>COLLEGE OF SCIENCE, INFORMATION AND COMMUNICATION TECHNOLOGY</td>
                                            </tr>
                                            <tr>
                                                <td>COLLEGE OF SCIENCE, INFORMATION AND COMMUNICATION TECHNOLOGY</td>
                                                <td>Five Credits at SSCE including English Language Mathematics, Physics, Biology and Chemistry at not more than two sittings.</td>
                                                <td>English Language, Mathematics, Chemistry, Biology or Physics.</td>
                                                <td>140 points</td>
                                                <td>N 1,200,000.00 (Without accommodation)</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div> */}
                                <h4 style={{ color: 'maroon', marginTop:'40px' }} >Method of application </h4>
                                <ol>
                                    <li>
                                        <p>Candidates are to visit JAMB Portal <a href="https://www.jamb.gov.ng" target={"_blank"}>www.jamb.gov.ng</a> to change their University of choice and Programme of studies to AUM</p>
                                    </li>
                                    <li>
                                        <p>In addition all candidates are to ensure that they upload their ‘O' Level results with at least five (5) Credit passes including English Language and Mathematics at not more than two (2) sittings and UTME  results on the JAMB website and Al-Ansar University Portal www.aum.edu.ng; and</p>
                                    </li>
                                    <li>
                                        <p>Candidates are to pay N2, 000:00 (two thousand naira) for Post UTME Screening exercise through the University Portal. </p>
                                    </li>
                                </ol>
                                {/* <h4 style={{ color: "maroon" }}>How To Apply</h4> */}
                                <p className="mb-5 text-justify">
                                    The application process for entry into the programmes offered
                                    by our university are relatively similar across all
                                    departments and faculties. This seamless and simplified
                                    process is geared towards ensuring that our applicants are
                                    comfortable regardless of the level of their computing
                                    literacy.
                                </p>
                                <div className="mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <h4>Before You Apply</h4>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-primary" /> Find a Course
                                            </div>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-primary" /> Register
                                            </div>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-primary" /> Submit Your Application Online
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <h4>After You Applied</h4>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-primary" /> Login
                                            </div>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-primary" />Track Your Application
                                            </div>
                                            <div className="col-md-12">
                                                <p className="fa fa-check-circle text-primary" />Download Your Admission Letter
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    You can submit multiple applications to different courses, but not multiple application to thesame course in thesame year. Kindly note that you are to download you admission letter from the portal when you are admitted. An email notification would be sent to you about the outcome of your application via the email address you provided.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card mb-3 mt-5">
                                <div className="card-body">
                                    <h5 className="mb-4" style={{ fontFamily: "revert-layer" }}>Apply Now</h5>
                                    {dateline.IsAlwaysOpen < 1 && (
                                        <div className="alert alert-warning">
                                            Application Dateline:{" "}
                                            {formatDateAndTime(dateline.EndDate, "date")}
                                        </div>
                                    )}
                                    <div className="">

                                        <Link to={"/admission/application/register"}>
                                            <p className="btn btn-primary bnt-sm w-100 mb-5">Register</p>
                                        </Link>
                                        <Link to={"/admission/application/login"}>
                                            <p className="btn btn-primary w-100">Login</p>
                                        </Link>

                                    </div>

                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <h5>Contact Us</h5>
                                    <div className="feature-item">
                                        <a href={`tel:${projectPhone}`}>
                                            <h4 className="fa fa-phone fa-1x text-primary mb-3" />
                                            {projectPhone}
                                        </a>

                                    </div>
                                    <div className="feature-item">
                                        <a href={`mailto:${projectEmail}`}>
                                            <h4 className="fa fa-book fa-1x text-primary" /> Send us mail
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {props.courseList.length > 0 && (
                <div className="container mb-5">
                    <div className="mt-5">
                        <div className="mb-5">
                            <h3>Find Other Programs</h3>
                        </div>
                        {props.courseList.length > 0 ? (
                            <div className="row">
                                {props.courseList.map((item, key) => {
                                    return <div className="col-lg-4 col-md-6 mb-4" key={key}>
                                        <div className="course-item">
                                            <a className="badge-primary" style={{ padding: "5px", borderRadius: "5px" }} href="#">{item.FacultyName.replace("College of", "")}</a>

                                            <div className="course-conten">
                                                <h5 className="mb-3">
                                                    <Link to={`/course/${item.Slug}`}>{item.CourseName}</Link>
                                                </h5>
                                                <div className="course-meta">
                                                    <ul className="list-unstyled mb-0">
                                                        <li><i className="fa fa-clock pr-2" />{item.Duration} {item.DurationType}</li>
                                                        <li><i className="fa fa-user pr-2" />{item.CourseClass}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        ) : (
                            <h3>No Course Found</h3>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        courseList: state.CourseListDetails,
    };
};
export default connect(mapStateToProps, null)(ApplicationLandingPage);
