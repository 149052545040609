import React from "react";
import InnerHeader from "../common/inner-header/inner-header";
import {projectAddress, projectEmail, projectPhone} from "../../resources/url";
import bg from "../../images/bg/03.jpg";
import Banner from "../common/banner/banner";

function ContactUs() {
   return (
       <>
           <Banner
               Image={bg}
               Title={"Contact Us"}
               breadcrumb={["Home", "Contact Us"]}
           />

           <section className="space-ptb">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-7 mb-4 mb-lg-0">
                           <div className="container-fluid p-0">
                               <div className="row no-gutters">
                                   <div className="col-sm-12">
                                       <div className="map h-500">
                                           <iframe
                                               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3904.5423245633056!2d13.12316931440197!3d11.867276141356484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x110499ecb085b86d%3A0x4fad99a09d93d03!2sAl%20Ansar%20UNIVERSITY%20Maiduguri!5e0!3m2!1sen!2sng!4v1662764955752!5m2!1sen!2sng"
                                               width="600" height="450" style={{border:0}} allowFullScreen=""
                                               loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
                                       </div>
                                   </div>
                               </div>
                           </div>

                       </div>
                       <div className="col-lg-4">
                           <div className="row">
                               <div className="col-lg-12">
                                   <div className="d-flex mb-3 bg-primary p-4 border-radius mb-4">
                                       <div className="text-primary mr-3">
                                           <i className="fas fa-3x fa-map-signs text-white"/>
                                       </div>
                                       <div className="recent-post-info">
                                           <span
                                               className="text-white">{projectAddress}</span>
                                       </div>
                                   </div>
                               </div>
                               <div className="col-lg-12">
                                   <div className="d-flex mb-3 bg-light p-4 border-radius mb-4">
                                       <div className="text-primary mr-3">
                                           <i className="far fa-3x fa-envelope"/>
                                       </div>
                                       <div className="recent-post-info">
                                           <span>{projectEmail}<br/> Mon-Fri 8:30am-6:30pm</span>
                                       </div>
                                   </div>
                               </div>
                               <div className="col-lg-12 mb-4">
                                   <div className="d-flex bg-dark p-4 border-radius">
                                       <div className="text-white mr-3">
                                           <i className="fas fa-3x fa-headphones-alt"/>
                                       </div>
                                       <div className="recent-post-info">
                                           <span className="text-white">{projectPhone} <br/> +234 806 944 2330<br/></span>
                                       </div>
                                   </div>
                               </div>
                               <div className="col-lg-12">
                                   <div className="d-flex bg-dark p-4 border-radius">
                                       <div className="text-white mr-3">
                                           <i className="fas fa-3x fa-headphones-alt"/>
                                       </div>
                                       <div className="recent-post-info">
                                           <span className="text-white">+234 703 238 6932 <br/> +234 813 399 8000<br/> 24 X 7 online support</span>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
       </>
   )
}

export default ContactUs;
