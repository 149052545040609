
import './modal.css'

const Modal = (props) => {
    return (
        <div>

            <div id={props.id} className="modal_t"  >
                <div className="modal__content_t" >
                    <a href='#' className='modal__close_t' id="clodemodal">x</a>

                    <div className='mt-5'>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Modal;