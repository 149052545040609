import React, { useEffect, useState } from "react";
import Banner from "../common/banner/banner";
import bg3 from "../../images/bg/03.jpg";
import Logo from "../../images/logo.png";
import { connect } from "react-redux";
import { formatDateAndTime, serverLink, shortCode } from "../../resources/url";
import { NavLink, useParams } from "react-router-dom";
import { setGeneralDetails, setNewsDetails } from "../../actions/setactiondetails";
import axios from "axios";
import * as DOMPurify from 'dompurify';

const EventDetails = (props) => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [eventData, seteventData] = useState(props.eventData !== "" ? props.eventData : "");
    const [eventList, setEventList] = useState(props.eventList.length > 0 ? props.eventList : []);
    const [eventList_1, seteventList_1] = useState(props.eventList.length > 0 ? props.eventList : []);
    const [change, setChange] = useState("");

    const getData = async () => {
        await axios
            .get(`${serverLink}event/all/active`)
            .then((result) => {
                const data = result.data;
                seteventList_1(data);
                setEventList(data);
                props.setOnEventDetails(data);
                seteventData(data.filter(x=>x.Slug === params.slug)[0])
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };
    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getData();
    }, [])

    const getDateMonth = (date) => {
        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const d = new Date(date);
        return monthNames[d.getMonth()];
    };

    const getDateDay = (date) => {
        const d = new Date(date);
        return d.getDay();
    };

    const getDateYear = (date) => {
        const d = new Date(date);
        return d.getFullYear();
    };

    useEffect(() => {
        seteventData(props.eventData !== "" ? props.eventData : eventList_1.filter(x=>x.Slug === params.slug)[0]);
      }, [change]);

      const changeScreen = (items) => {
        setChange(items);
      };

      const handleSearch = (e)=>{
        let filtered = eventList_1.filter(x=>x.Title.toLowerCase().includes(e.target.value.toLowerCase()));
        setEventList(filtered)
    }

    return (
        <div>
            <Banner
                Image={bg3}
                Title={"Events"}
                breadcrumb={["Home", "Event Details"]}
            />
            <section className="space-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-5 mb-lg-0">
                            <div className="blog-sidebar">
                                <div className="widget">
                                    <div className="widget-title">
                                        <h5>Search</h5>
                                    </div>
                                    <form className="navbar-form search d-flex">
                                        <input type="text" name="q" className="form-control input-search rounded-0 bg-white" placeholder="Search" onChange={handleSearch} autoComplete="off" />
                                        <a><i className="fa fa-search text-white" /></a>
                                    </form>
                                </div>
                                <div className="widget">
                                    <div className="widget-title">
                                        <h5>Other Events</h5>
                                    </div>
                                    {
                                        eventList.length > 0 ?
                                            (
                                                eventList.sort(() => Math.random() - 0.5).map((item, index) => {
                                                    const bottomClicked = async (item) => {
                                                        const newsData = { ...item };
                                                        setGeneralDetails(newsData);
                                                        changeScreen(item)
                                                       // props.channgeScreen(item);
                                                      };
                                                    return (
                                                        <div className="media mb-3 pb-3 border-bottom" key={index}>
                                                            <img className="img-fluid avatar border-radius" src={eventData.ImagePath === undefined || eventData.ImagePath === null || eventData.ImagePath === "" ? Logo : eventData.ImagePath.includes("simplefileupload") ? eventData.ImagePath : `${serverLink}public/uploads/${shortCode}/event_uploads/${eventData.ImagePath}` } alt="" />
                                                            <div className="media-body ml-3">
                                                            <NavLink className="text-dark" to={`/events/${item.Slug}`} 
                                                            onClick={()=>{
                                                                bottomClicked(item); 
                                                            }} >

                                                                    <b>{item.Title}</b>
                                                                </NavLink>
                                                                
                                                                <div className="events-meta">
                                                                    <ul className="list-unstyled mb-0 d-flex">
                                                                        <li><i className="fas fa-calendar-alt text-danger" /> 
                                                                        {formatDateAndTime(item.EventDate,"date")}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                            :
                                            "No Similar events"
                                    }
                                </div>
                                {/* events */}
                                {/* Follow on */}
                                <div className="widget">
                                    <div className="widget-title">
                                        <h5>Follow on</h5>
                                    </div>
                                    <div className="social">
                                        <ul className="list-unstyled">
                                            <li><a target={"_blank"} href="https://www.facebook.com"> <i className="fab fa-facebook-f" /> </a></li>
                                            <li><a target={"_blank"} href="https://instagram.com/?lang=en"> <i className="fab fa-instagram" /> </a></li>
                                            <li><a target={"_blank"} href="https://www.twitter.com/"> <i className="fab fa-twitter" /> </a></li>
                                            <li><a target={"_blank"} href="https://linkedin.com/?lang=en"> <i className="fab fa-linkedin" /> </a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Follow on */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="events">
                                <div className="events-img text-center">
                                    <img className="img-fluid border-0" src={eventData.ImagePath === undefined || eventData.ImagePath === null || eventData.ImagePath === "" ? Logo : eventData.ImagePath.includes("simplefileupload") ? eventData.ImagePath :`${serverLink}public/uploads/${shortCode}/event_uploads/${eventData.ImagePath}`} alt="" />
                                </div>
                                <div className="events-content mt-4">
                                    {/* <div className="events-tag">
                                    <a href="#" className="badge-danger mb-2">{eventData.Title}</a>
                                </div> */}
                                    <a href="#" className="text-dark h5">{eventData.Title}</a>
                                    <div className="events-meta my-2">
                                        <ul className="list-unstyled mb-0 d-flex">
                                            <li className="mr-3"><a href="#"><i className="fas fa-calendar-alt pr-1" />
                                                {getDateDay(eventData.EventDate)} {getDateMonth(eventData.EventDate)}, {getDateYear(eventData.EventDate)}</a></li>
                                            <li><a href="#"><i className="fas fa-clock pr-1" />   {eventData.StartTime} - {eventData.EndTime}</a></li>
                                        </ul>
                                    </div>
                                    <p className="text-justify" ><span dangerouslySetInnerHTML={{ __html: `${DOMPurify.sanitize(eventData.Description)}` }} /></p>
                                </div>
                            </div>
                            <div className="mt-4 mt-lg-5">
                                <h5 className="mb-3">Event Details</h5>
                                <ul className="list-inline d-sm-flex d-inline-block border-radius bg-primary px-4 justify-content-between py-2 w-100">
                                    <li className="my-3">
                                        <h6 className="text-white d-block mb-2">Start Time</h6>
                                        <span className="text-white">{eventData.StartTime}</span>
                                    </li>
                                    <li className="my-3">
                                        <h6 className="text-white d-block mb-2">End Time</h6>
                                        <span className="text-white">{eventData.EndTime}</span>
                                    </li>
                                    <li className="my-3">
                                        <h6 className="text-white d-block mb-2">Date</h6>
                                        <span className="text-white">{formatDateAndTime(eventData.EventDate, "date")}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        eventData: state.GeneralDetails,
        eventList: state.EventDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnEventDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);


