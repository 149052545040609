import axios from "axios";
import React, { useEffect, useState } from "react";
import { serverLink, shortCode } from "../../../resources/url";
import Banner from "../../common/banner/banner";
import bg3 from "../../../images/bg/03.jpg";

const Gallery = (props) => {
    const [galleryCat, setGalleryCat] = useState([]);
    const [subCat, setSubCat] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);
    const [galleryImageMui, setGalleryImageMui] = useState([]);
    const [gItems, setGItems] = useState([])
    const [galleryImages2, setGalleryImages2] = useState([]);
    const [NotActive, setNotActive] = useState('');
    const [Active, setActive] = useState('')

    async function getGalleryImages() {
        await axios
            .get(`${serverLink}gallery/list`)
            .then((response) => {
                const data = response.data;
                let gallery_images = [];
                let Category = [];
                let Subcategory = [];
                data.map((gallery, index) => {
                    gallery_images.push({
                        title: gallery.ImageTitle, // require
                        original: `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}`, // require
                        thumbnail: `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}`, // optional
                    });

                    Category.push(gallery.CategoryName);
                    Subcategory.push({
                        subCatName: gallery.SubCategoryName,
                        catName: gallery.CategoryName,
                    });
                });
                setGalleryImages2(data)
                setGalleryImages(data);
                setGalleryImageMui(gallery_images);

                let uniqueCategory = Category.filter(
                    (item, i, ar) => ar.indexOf(item) === i
                );
                setGalleryCat(uniqueCategory);

                let filteredSubCat = [];
                for (let i = 0; i < Subcategory.length; i++) {
                    if (filteredSubCat.length === 0) {
                        filteredSubCat.push(Subcategory[i]);
                    } else {
                        let check = filteredSubCat.filter(
                            (item) =>
                                Subcategory[i].subCatName === item.subCatName &&
                                Subcategory[i].catName === item.catName
                        );
                        if (check.length < 1) {
                            filteredSubCat.push(Subcategory[i]);
                        }
                    }
                }
                setSubCat(filteredSubCat);
            })
            .catch((error) => {
                console.log("SERVER ERROR", error);
            });
    }

    useEffect(() => {
        getGalleryImages();
    }, []);

    const handleGalleryChange = (e, type) => {
       if(type !== "all"){
        let filetered  = galleryImages2.filter(x=> x.CategoryName === type);
        setGalleryImages(filetered);
       }

        let g_items = []
        let gallery_images = [];
        if (type === "cat") {
            if (e !== "all")
                galleryImages
                    .filter((item) => item.CategoryName === e)
                    .map((gallery) => {
                        g_items = gallery;
                        gallery_images.push({
                            title: gallery.ImageTitle, // require
                            original: `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}`, // require
                            thumbnail: `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}`, // optional
                        });
                    });
            else
                galleryImages.map((gallery) => {
                    g_items = gallery;
                    gallery_images.push({
                        title: gallery.ImageTitle, // require
                        original: `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}`, // require
                        thumbnail: `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}`, // optional
                    });
                });
        } else {
            galleryImages
                .filter(
                    (item) =>
                        item.CategoryName === e.catName &&
                        item.SubCategoryName === e.subCatName
                )
                .map((gallery) => {
                    g_items = gallery;
                    gallery_images.push({
                        title: gallery.ImageTitle, // require
                        original: `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}`, // require
                        thumbnail: `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}`, // optional
                    });
                });
        }
        setGItems(g_items)
        setGalleryImageMui(gallery_images);
    };

    return (
        <>
            <Banner
                Image={bg3}
                Title={"Gallery"}
                breadcrumb={["About Us", "Gallery"]}
                height="50px" />

            <section className="space-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="filters-group mb-lg-4 text-center">
                                <button className="btn-filter  active" id="all" onClick={(e) => handleGalleryChange(e, "all")} data-group="all">All</button>
                                {
                                    galleryCat.length > 0 &&
                                    galleryCat.map((x, i) => {
                                        return (
                                            <button key={i} className={`btn-filter ${Active}`} id={x} onClick={(e) => handleGalleryChange(e, x)} data-group={x}>{x}</button>
                                        )
                                    })
                                }

                            </div>
                            {
                                galleryImages.length > 0 &&
                                <div className="row col-md-12">
                                    {
                                        galleryImages.map((item, index) => {
                                            return (
                                                <div className="col-md-3" key={index}  >
                                                    <div className="portfolio-item">
                                                        <div className="position-relative">
                                                            <img className="img-fluid" 
                                                            src={item.ImagePath !== null ? item.ImagePath.includes("simplefileupload") ? item.ImagePath :  `${serverLink}public/uploads/${shortCode}/leaderships_uploads/${item.ImagePath}` : ""}

                                                            // src={`${serverLink}public/uploads/${shortCode}/gallery/${item.ImagePath}`} 

                                                            alt="" />
                                                            <div className="portfolio-overlay">
                                                                <a className="portfolio-img" 
                                                                href={item.ImagePath !== null ? item.ImagePath.includes("simplefileupload") ? item.ImagePath :  `${serverLink}public/uploads/${shortCode}/leaderships_uploads/${item.ImagePath}` : ""}><i className="fas fa-arrows-alt" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Gallery;