import axios from "axios";
import React, { useEffect, useState } from "react";
import { serverLink } from "../../../../resources/url";
import Banner from "../../../common/banner/banner";
import Loader from "../../../common/loader/loader";
import bg3 from "../../../../images/bg/09.jpg";
import { Link } from "react-router-dom";
import JobCards from "./job-cards";
import JobOpeningsSide from "./job-openings-side";


const JobOpenings = (props) => {
    const [loading, setLoading] = useState(true);
    const [jobList, setJobList] = useState([]);
    const [jobList2, setJobList2] = useState([]);
    const [filter, setFilter] = useState([]);
    const [byFaculty, setByFaculty] = useState([]);
    const [FacultyList, setFacultyList] = useState([]);

    const setJobsByCategories = (e) => {
        setJobList(jobList2.filter((x) => x.Faculty.includes(e)));
    };
    const setAllJobs = () => {
        setJobList(jobList2);
    };

    const SearchJob = (e) => {
        const filtered = filter.filter(
            (item) =>
                item.Faculty.toLowerCase().includes(e.target.value.toLowerCase()) ||
                item.Position.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setJobList(filtered);
    };

    const getData = async () => {
        await axios
            .get(`${serverLink}jobs/job-openings/list`)
            .then((result) => {
                const data = result.data;
                setFilter(data);
                setJobList(data);
                setJobList2(data);
            })
            .catch((error) => {
                console.log("error", error);
            });

        await axios
            .get(`${serverLink}jobs/job-openings/by_faculty/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setByFaculty(result.data);
                }
            })
            .catch((error) => {
                console.log("error", error);
            });

        await axios
            .get(`${serverLink}jobs/faculty/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setFacultyList(result.data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        getData();
    }, []);


    return loading ? (<Loader />) : (
        <>
            <Banner
                Image={bg3}
                Title={"Job Openings"}
                breadcrumb={["Resources", "Jobs"]}
                height="50px"
            />
            <div className="course-archive bg-dark py-3 px-4 mb-5 border-radius">
                <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-md-4">
                        <div className="d-flex">
                            <div className="text-white">
                                showing {jobList.length} openings
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 mt-4 mt-md-0">
                        <form className="navbar-form search d-flex">
                            <input type="text" name="search" onChange={SearchJob} className="form-control input-search rounded-0 bg-white" placeholder="search" autoComplete="off" />
                            <a href="#"><i className="fa fa-search text-white" /></a>
                        </form>
                    </div>
                </div>
            </div>
            <section className="space-pb pt-5">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-9">
                            <JobCards jobList={jobList} />
                        </div>

                        <div className="col-lg-3">
                            <JobOpeningsSide
                                jobList={jobList}
                                jobList2={jobList2}
                                SearchJob={SearchJob}
                                byFaculty={byFaculty}
                                setJobsByCategories={setJobsByCategories}
                                setAllJobs={setAllJobs}
                                FacultyList={FacultyList}
                            />
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}
export default JobOpenings;